import React from "react";

import Layout from "../layouts";
import SEO from "../components/seo";

const NotFoundPage = () => {
  const browser = typeof window !== "undefined" && window;

  if (!browser) {
    return <div></div>;
  }

  return (
    <>
      <SEO title="404: Not found" />
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </>
  );
};

export default NotFoundPage;
